/* eslint-disable react/no-multi-comp */
import type React from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

import { MarginsWrapper } from '../Margins/Margins';
import type { Props as PictogramProps } from '../Pictogram/Pictogram';
import { Pictogram } from '../Pictogram/Pictogram';
import { Typo } from '../Typo/Typo';
import { iconLinkStyles } from './styled';

const StyledIconLinkButton = styled.button`
  ${({ disabled }): FlattenSimpleInterpolation => iconLinkStyles(disabled)}
`;

export interface IconLinkButtonProps {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  type?: 'button' | 'reset' | 'submit';
  iconDirection?: 'left' | 'right';
}

const IconLinkButtonWrapper: React.FC<IconLinkButtonProps & PictogramProps> = ({
  text,
  children,
  type = 'button',
  iconDirection = 'left',
  ...props
}) => (
  <StyledIconLinkButton type={type} {...props}>
    {iconDirection === 'left' && children}
    <MarginsWrapper
      marginLeft={iconDirection === 'left' ? 'S' : undefined}
      marginRight={iconDirection === 'right' ? 'S' : undefined}
    >
      <Typo $type="bodyBold" noMargin>
        {text}
      </Typo>
    </MarginsWrapper>
    {iconDirection === 'right' && children}
  </StyledIconLinkButton>
);

export const IconLinkButton: React.FC<IconLinkButtonProps & PictogramProps> = ({
  text,
  icon = 'arrow-right',
  type = 'button',
  iconDirection = 'left',
  ...props
}) => (
  <IconLinkButtonWrapper
    iconDirection={iconDirection}
    text={text}
    type={type}
    {...props}
  >
    <Pictogram
      icon={icon}
      iconColor={props.disabled ? 'greyLight' : 'orangeMedium'}
    />
  </IconLinkButtonWrapper>
);
