/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useMemo } from 'react';
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { primaryColors } from '../../colors';
import { Checkbox } from '../Checkbox/Checkbox';
import { CollapseBody } from '../Collapse/CollapseBody';
import { Pictogram } from '../Pictogram/Pictogram';
import type { IOption } from './Select';

interface SelectListProps {
  isOpen?: boolean;
  dense?: boolean;
  _extremeDense?: boolean;
}

const SelectList = styled.div<SelectListProps>`
  position: absolute;
  z-index: 1;
  width: 100%;
  top: ${({ dense }): CSSProp => (dense ? '48px' : '60px')};
  ${({ dense, _extremeDense }): CSSProp =>
    dense && !_extremeDense
      ? css`
          top: 48px;
        `
      : css`
          top: 60px;
        `}
  ${({ _extremeDense }): CSSProp =>
    _extremeDense
      ? css`
          top: 32px;
        `
      : ''}
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1),
    0px 8px 16px rgba(65, 65, 65, 0.1), 0px 16px 32px rgba(65, 65, 65, 0.2);
  border-radius: 3px;
  max-height: 418px;
  overflow: auto;
  transition: padding 300ms ease-in-out;
  ${({ isOpen }): CSSProp =>
    isOpen ? 'padding : 8px 0 14px 0' : 'padding: 0'};
`;

interface ListButtonProps {
  selected: boolean;
  isOpen?: boolean;
  isMulti?: boolean;
}

const ListButton = styled.button<ListButtonProps>`
  position: relative;
  font-family: NNDagnyText;
  font-size: 16px;
  height: 48px;
  width: 100%;
  text-align: left;
  padding: ${({ isMulti }): CSSProp => (isMulti ? '0 42px 0 16px' : '0 16px')};
  background: transparent;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:focus,
  &:hover {
    background: ${primaryColors.snowWhite};
  }

  ${({ selected }): CSSProp =>
    (selected &&
      css`
        font-family: NNDagnyDisplay;
        background: ${primaryColors.greyWhite};
      `) ||
    ''}
`;

const ListButtonIconWrapper = styled.span<{ isMulti?: boolean }>`
  position: absolute;
  right: 16px;

  ${({ isMulti }): CSSProp =>
    isMulti
      ? css`
          top: 10px;
        `
      : ''};
`;

export interface IOptionsList<T, R> {
  options: IOption<T>[];
  value?: R;
  onChange: (value: R) => void;
  isOpen?: boolean;
  isMulti?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dense?: boolean;
  _extremeDense?: boolean;
}

export function OptionsList<T, R>({
  isOpen,
  options,
  onChange,
  setIsOpen,
  value,
  isMulti,
  _extremeDense,
  dense,
}: IOptionsList<T, R>): JSX.Element {
  const arrayValue = useMemo(
    () => (Array.isArray(value) ? (value as T[]) : [value as unknown as T]),
    [value],
  );

  const selected = (option: IOption<T>): boolean =>
    arrayValue?.includes(option.value) ||
    (arrayValue as unknown as string[])?.includes(option.option as string);

  const updateArrayValue = useCallback(
    (option: IOption<T>) => {
      const updatedValues = new Set(arrayValue);
      updatedValues.has(option.value);

      if (updatedValues.has(option.value)) {
        updatedValues.delete(option.value);
      } else {
        updatedValues.add(option.value);
      }

      return [...updatedValues];
    },
    [arrayValue],
  );

  const handleChange = useCallback(
    (option: IOption<T>, event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      event?.stopPropagation();

      onChange(
        (isMulti ? updateArrayValue(option) : option.value) as unknown as R,
      );
      setIsOpen(!!isMulti);
    },
    [isMulti, onChange, updateArrayValue, setIsOpen],
  );

  return (
    <SelectList _extremeDense={_extremeDense} dense={dense} isOpen={isOpen}>
      <CollapseBody animationTime={300} isOpen={!!isOpen} scrollOnOpen={false}>
        {options.map((option) => (
          <ListButton
            isMulti={isMulti}
            isOpen={isOpen}
            key={`${selected(option)}-${option.option}-${option.value}`}
            onClick={(event): void => handleChange(option, event)}
            selected={selected(option)}
          >
            {option.option || option.value}
            <ListButtonIconWrapper isMulti={isMulti}>
              {!isMulti && selected(option) && <Pictogram icon="ok" />}
              {isMulti && (
                <Checkbox
                  onChange={(): void => handleChange(option)}
                  value={selected(option)}
                />
              )}
            </ListButtonIconWrapper>
          </ListButton>
        ))}
      </CollapseBody>
    </SelectList>
  );
}
