/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { nnColors } from '../colors';

export const convertHexToRgba = (
  color: keyof typeof nnColors,
  opacity: string,
): string | undefined => {
  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(
    nnColors[color],
  );
  return result && result[1] && result[2] && result[3]
    ? `rgba(${Number.parseInt(result[1], 16)}, ${Number.parseInt(
        result[2],
        16,
      )}, ${Number.parseInt(result[3], 16)}, ${opacity})`
    : undefined;
};
