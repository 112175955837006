import type React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import type { PaddingsProps } from '../Margins/Margins';
import { PaddingsWrapper } from '../Margins/Margins';

export interface GridProps
  extends React.HTMLAttributes<HTMLDivElement>,
    PaddingsProps {
  block?: boolean;
  vertical?: boolean;
  center?: boolean;
  align?: boolean;
  wrap?: boolean;
  flex?: number;
  justify?: string;
  alignItems?: string;
}

export const Grid = styled(PaddingsWrapper)<GridProps>`
  box-sizing: border-box;
  display: ${({ block }: GridProps): CSSProp => (block ? 'block' : 'flex')};
  flex: ${({ flex }: GridProps): number => (flex || flex === 0 ? flex : 1)};
  flex-direction: ${({ vertical }: GridProps): CSSProp =>
    vertical ? 'column' : ''};
  justify-content: ${({ center }: GridProps): CSSProp =>
    center ? 'center' : ''};
  align-items: ${({ align }: GridProps): CSSProp => (align ? 'center' : '')};

  ${({ wrap }: GridProps): CSSProp =>
    (wrap &&
      css`
        flex-wrap: wrap;
        align-items: flex-start;
      `) ||
    ''};

  ${({ justify }: GridProps): CSSProp =>
    (justify &&
      css`
        justify-content: ${justify};
      `) ||
    ''};

  ${({ alignItems }: GridProps): CSSProp =>
    (alignItems &&
      css`
        align-items: ${alignItems};
      `) ||
    ''};
`;
