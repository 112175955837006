import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors } from '../../colors';
import { MarginsWrapper } from '../Margins/Margins';
import { RoundedPictogram } from '../RoundedPictogram/RoundedPictogram';
import { Typo } from '../Typo/Typo';

const ErrorFieldWrapper = styled.span<{ error: boolean }>`
  align-items: center;
  margin-top: 6px;
  ${Typo} {
    color: ${nnColors.red};
  }
  ${({ error }): CSSProp =>
    (error &&
      css`
        display: flex;
      `) ||
    css`
      display: none;
    `}
`;

export const ErrorField: React.FC<{ error?: string }> = ({
  error,
  ...props
}) => (
  <ErrorFieldWrapper {...props} error={!!error}>
    <RoundedPictogram
      background="red"
      icon="alert"
      iconColor="white"
      iconSize="xs"
    />
    <MarginsWrapper marginLeft="XS">
      <Typo $type="caption">{error}</Typo>
    </MarginsWrapper>
  </ErrorFieldWrapper>
);
