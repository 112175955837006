/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/naming-convention */
import type React from 'react';
import type { FC } from 'react';
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors } from '../../colors';
import { Grid } from '../Grid/Grid';
import type { MarginsProps } from '../Margins/Margins';
import { MarginsWrapper } from '../Margins/Margins';
import { icons } from './icons';

interface PictogramFrameProps {
  iconColor?: keyof typeof nnColors;
  fill?: keyof typeof nnColors;
  iconSize?: 'l' | 'm' | 's' | 'sl' | 'xl' | 'xs' | 'xxl' | 'xxxl';
  strokeWidth?: 1 | 2 | 3;
}

const getSizeValue = (iconSize?: PictogramFrameProps['iconSize']): number => {
  if (iconSize === 'xxxl') {
    return 120;
  }

  if (iconSize === 'xxl') {
    return 80;
  }

  if (iconSize === 'xl') {
    return 48;
  }

  if (iconSize === 'l') {
    return 32;
  }

  if (iconSize === 'm') {
    return 24;
  }

  if (iconSize === 'sl') {
    return 16;
  }

  if (iconSize === 's') {
    return 10.67;
  }

  if (iconSize === 'xs') {
    return 8;
  }

  return -1;
};

const PictogramFrame = styled(
  MarginsWrapper.withComponent(Grid),
)<PictogramFrameProps>`
  overflow: hidden;
  position: relative;

  min-width: ${({ iconSize }): number => getSizeValue(iconSize)}px;
  min-height: ${({ iconSize }): number => getSizeValue(iconSize)}px;
  max-width: ${({ iconSize }): number => getSizeValue(iconSize)}px;
  max-height: ${({ iconSize }): number => getSizeValue(iconSize)}px;

  svg {
    position: absolute;
    left: 0;
    right: 0;
    transform: scale(${({ iconSize }): number => getSizeValue(iconSize) / 24});
    transform-origin: 0 0;
  }

  ${({ strokeWidth }): CSSProp =>
    (strokeWidth &&
      css`
        svg path {
          stroke-width: ${strokeWidth};
        }
      `) ||
    ''}

  ${({ iconColor }: PictogramFrameProps): CSSProp =>
    (iconColor &&
      css`
        svg path {
          stroke: ${nnColors[iconColor]};
        }
      `) ||
    ''};

  ${({ fill }: PictogramFrameProps): CSSProp =>
    (fill &&
      css`
        svg path {
          fill: ${nnColors[fill]};
        }
      `) ||
    ''};
`;

export interface Props extends PictogramFrameProps, MarginsProps {
  icon?: keyof typeof icons;
  Icon?: typeof React.Component;
}

export const Pictogram: FC<Props> = ({
  icon,
  Icon,
  iconSize = 'm',
  ...props
}) => {
  const IconFromIndex = icon && icons[icon];

  return (
    <PictogramFrame iconSize={iconSize} {...props}>
      {Icon && <Icon />}
      {!Icon && IconFromIndex && <IconFromIndex title={icon} />}
    </PictogramFrame>
  );
};
