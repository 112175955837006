/* eslint-disable import/max-dependencies */
/* eslint-disable simple-import-sort/imports */
// icons
import Alert from './alert.svg';
import ArrowDown from './arrow-down.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import ArrowFirst from './arrow-first.svg';
import ArrowLast from './arrow-last.svg';
import ArrowRaquo from './arrow-raquo.svg';
import Close from './close.svg';
import Sliders from './sliders.svg';
import MoreVertical from './more-vertical.svg';
import OK from './ok.svg';
import Search from './search.svg';
import Warning from './warning.svg';
import Sort from './sort.svg';
import ArrowUpFull from './arrow-up-full.svg';
import ArrowDownFull from './arrow-down-full.svg';
import PhoneOff from './phone-off.svg';
import Phone from './phone.svg';
import Calendar from './calendar.svg';
import ShoppingCart from './shoppingcart.svg';
import Save from './save.svg';
import AddRound from './add-round.svg';
import Trash from './trash.svg';
import InfoRound from './info-round.svg';
import Plus from './plus.svg';
import SubtractRound from './subtract-round.svg';
import Send from './send.svg';
import Coffee from './coffee.svg';
import Reload from './reload.svg';
import Upload from './upload.svg';
import Document from './document.svg';
import Sides from './sides.svg';
import SidesBold from './sides-bold.svg';
import AttachmentBig from './attachment-big.svg';
import Attachment from './attachment.svg';
import Bookmark from './bookmark.svg';
import Clock from './clock.svg';
import CloudSaved from './cloud-saved.svg';
import Copy from './copy.svg';
import Email from './email.svg';
import Exit from './exit.svg';
import EyeOff from './eye-off.svg';
import Folder from './folder.svg';
import Gear from './gear.svg';
import Link from './link.svg';
import Lock from './lock.svg';
import Mail from './mail.svg';
import More from './more.svg';
import Paragraph from './paragraph.svg';
import Pin from './pin.svg';
import Signature from './signature.svg';
import Star from './star.svg';
import Unlock from './unlock.svg';
import ArrowUp from './arrow-up.svg';
import ArrowLaquo from './arrow-laquo.svg';
import ArrowLeftFull from './arrow-left-full.svg';
import ArrowRightFull from './arrow-right-full.svg';
import ArrowMaximise from './arrow-maximise.svg';
import ArrowMinimise from './arrow-minimise.svg';
import ArrowRedo from './arrow-redo.svg';
import ArrowUndo from './arrow-undo.svg';
import ArrowDownDouble from './arrow-down-double.svg';
import ArrowUpDouble from './arrow-up-double.svg';
import Download from './download.svg';
import Share from './share.svg';
import Eye from './eye.svg';
import Pause from './pause.svg';
import Euro from './euro.svg';
import OKRound from './ok-round.svg';
import RandomCoin from './random-coin.svg';
import Payments from './payments.svg';
import Relocate from './relocate.svg';
import Minus from './minus.svg';
import Decrease from './decrease.svg';
import Growth from './growth.svg';
import MinusShort from './minus-short.svg';
import Fingerprint from './fingerprint.svg';
import Incident from './incident.svg';
import Favourites from './favourites.svg';
import Questionmark from './questionmark.svg';
import Edit from './edit.svg';
import Printer from './printer.svg';
import Calc from './calc.svg';
import CalcSheet from './calc-sheet.svg';
import Briefcase from './briefcase.svg';
import Home from './home.svg';
import Heart from './heart.svg';
import Bell from './bell.svg';
import Chart from './chart.svg';
import Graph from './graph.svg';
import User from './user.svg';
import Users from './users.svg';
import UserAnalisis from './user-analisis.svg';
import Chat from './chat.svg';
import CommunicationBubble from './communication-bubble.svg';
import Shield from './shield.svg';
import Umbrela from './umbrela.svg';
import Menu from './menu.svg';
import View from './view.svg';
import Apps from './apps.svg';
import CursorClick from './cursor-click.svg';
import Cursor from './cursor.svg';
import MicrophoneOff from './microphone-off.svg';
import Microphone from './microphone.svg';
import VideoOff from './video-off.svg';
import Video from './video.svg';
import Play from './play.svg';
import Cammera from './cammera.svg';
import Mobile from './mobile.svg';
import Desktop from './desktop.svg';
import Volume2 from './volume2.svg';
import Volume1 from './volume1.svg';
import Volume0 from './volume0.svg';
import VolumeOff from './volume-off.svg';
// pictographics
import Agent from './pictographics/agent.svg';
import Employees from './pictographics/employees.svg';
import Study1 from './pictographics/study-1.svg';
import Study2 from './pictographics/study-2.svg';
import ExternalLink from './external-link.svg';
import DocumentDetailed from './pictographics/document-detailed.svg';
import HotLead from './pictographics/hotlead.svg';
import ColdLead from './pictographics/coldlead.svg';
import Wallet from './pictographics/wallet.svg';
import EmployeeUmbrella from './pictographics/employee-umbrella.svg';
import Megaphone from './pictographics/megaphone.svg';
import Floor from './pictographics/floor.svg';
import Invest from './pictographics/invest.svg';
import Loan from './pictographics/loan.svg';
import Rocket from './pictographics/rocket.svg';
import ClipboardOk from './pictographics/clipboard-ok.svg';
import Lightbulb from './pictographics/lightbulb.svg';
import Sport3 from './pictographics/sport-3.svg';
import Furniture4 from './pictographics/furniture-4.svg';
import Contract from './pictographics/contract.svg';
import Will from './pictographics/will.svg';
import Institution from './pictographics/institution.svg';
import RoomPicDamages from './pictographics/room-pic-damages.svg';
import IdDocumentFront from './pictographics/id-document-front.svg';
import IdDocumentRear from './pictographics/id-document-rear.svg';
// files
import Loader from './loader.svg';
// invalid:
import Info from './invalid/info.svg';
import InfoSmall from './invalid/info-small.svg';
import AlertSmall from './invalid/alert-small.svg';

export const icons = {
  // invalid
  'alert-small': AlertSmall,
  'info-small': InfoSmall,
  info: Info,
  // icons
  'add-round': AddRound,
  alert: Alert,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-last': ArrowLast,
  'arrow-first': ArrowFirst,
  'arrow-raquo': ArrowRaquo,
  'arrow-laquo': ArrowLaquo,
  'arrow-up-full': ArrowUpFull,
  'arrow-down-full': ArrowDownFull,
  'arrow-left-full': ArrowLeftFull,
  'arrow-right-full': ArrowRightFull,
  'arrow-maximise': ArrowMaximise,
  'arrow-minimise': ArrowMinimise,
  'arrow-redo': ArrowRedo,
  'arrow-undo': ArrowUndo,
  'arrow-down-double': ArrowDownDouble,
  'arrow-up-double': ArrowUpDouble,
  contract: Contract,
  close: Close,
  download: Download,
  'external-link': ExternalLink,
  'more-vertical': MoreVertical,
  'id-document-front': IdDocumentFront,
  'id-document-rear': IdDocumentRear,
  'room-pic-damages': RoomPicDamages,
  sliders: Sliders,
  ok: OK,
  search: Search,
  warning: Warning,
  will: Will,
  sort: Sort,
  'phone-off': PhoneOff,
  'study-1': Study1,
  'study-2': Study2,
  employees: Employees,
  phone: Phone,
  calendar: Calendar,
  agent: Agent,
  'document-detailed': DocumentDetailed,
  'employee-umbrella': EmployeeUmbrella,
  send: Send,
  'subtract-round': SubtractRound,
  megaphone: Megaphone,
  shoppingcart: ShoppingCart,
  save: Save,
  hotlead: HotLead,
  coldlead: ColdLead,
  wallet: Wallet,
  floor: Floor,
  infoRound: InfoRound,
  trash: Trash,
  invest: Invest,
  institution: Institution,
  loan: Loan,
  plus: Plus,
  coffee: Coffee,
  reload: Reload,
  upload: Upload,
  document: Document,
  rocket: Rocket,
  clipboardok: ClipboardOk,
  lightbulb: Lightbulb,
  loader: Loader,
  sides: Sides,
  'sides-bold': SidesBold,
  'attachment-big': AttachmentBig,
  attachment: Attachment,
  bookmark: Bookmark,
  clock: Clock,
  'cloud-saved': CloudSaved,
  copy: Copy,
  email: Email,
  exit: Exit,
  eyeOff: EyeOff,
  folder: Folder,
  gear: Gear,
  link: Link,
  lock: Lock,
  mail: Mail,
  more: More,
  paragraph: Paragraph,
  pin: Pin,
  signature: Signature,
  star: Star,
  unlock: Unlock,
  share: Share,
  eye: Eye,
  pause: Pause,
  euro: Euro,
  'ok-Round': OKRound,
  'random-coin': RandomCoin,
  payments: Payments,
  relocate: Relocate,
  minus: Minus,
  decrease: Decrease,
  growth: Growth,
  'minus-short': MinusShort,
  fingerprint: Fingerprint,
  incident: Incident,
  favourites: Favourites,
  questionmark: Questionmark,
  edit: Edit,
  printer: Printer,
  calc: Calc,
  'calc-sheet': CalcSheet,
  briefcase: Briefcase,
  home: Home,
  heart: Heart,
  bell: Bell,
  chart: Chart,
  graph: Graph,
  user: User,
  users: Users,
  'user- analisis': UserAnalisis,
  chat: Chat,
  'communication-bubble': CommunicationBubble,
  shield: Shield,
  umbrela: Umbrela,
  menu: Menu,
  '360view': View,
  apps: Apps,
  'cursor-click': CursorClick,
  cursor: Cursor,
  'microphone-off': MicrophoneOff,
  microphone: Microphone,
  'video-off': VideoOff,
  video: Video,
  play: Play,
  cammera: Cammera,
  mobile: Mobile,
  desktop: Desktop,
  volume2: Volume2,
  volume1: Volume1,
  volume0: Volume0,
  'volume-off': VolumeOff,
  sport3: Sport3,
  furniture4: Furniture4,
} as const;
