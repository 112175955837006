import type { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components';

import { nnColors } from '../../colors';
import { Typo } from '../Typo/Typo';

export const iconLinkStyles = (
  disabled?: boolean,
): FlattenSimpleInterpolation => css`
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  border: none;
  background: transparent;

  path {
    transition: stroke-width 100ms;
  }

  ${disabled
    ? css`
        ${Typo} {
          color: ${nnColors.greyLight};
        }
        cursor: not-allowed;
      `
    : css`
        &:hover {
          path {
            stroke-width: 3px;
          }
          ${Typo} {
            color: ${nnColors.orangeMedium};
          }
        }
      `}
`;
