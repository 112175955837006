import type React from 'react';
import styled from 'styled-components';

import { nnColors } from '../../colors';
import { Grid } from '../Grid/Grid';
import { Logo } from '../Logo/Logo';
import { PaddingsWrapper } from '../Margins/Margins';

const NavbarPaddings = styled(PaddingsWrapper)`
  width: 100%;
  background: ${nnColors.white};
  border-bottom: 1px solid ${nnColors.greyWhite};
  display: flex;
  height: 80px;
  box-sizing: border-box;
  position: relative;
`;

const NavbarContent = styled(PaddingsWrapper)`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
`;

export interface NavbarProps {
  children?: React.ReactNode;
}

export const Navbar: React.VFC<NavbarProps> = ({ children, ...props }) => {
  return (
    <NavbarPaddings {...props} paddingsVertical="XL">
      <Grid align justify="space-between">
        <Logo />
        <NavbarContent paddingLeft="XL">{children}</NavbarContent>
      </Grid>
    </NavbarPaddings>
  );
};
