/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors } from '../../colors';
import { convertHexToRgba } from '../../utils/convertHexToRgba';
import { Checkbox } from '../Checkbox/Checkbox';
import { Elevation } from '../Elevation/Elevation';
import { Grid } from '../Grid/Grid';
import { MarginsWrapper } from '../Margins/Margins';
import type { icons } from '../Pictogram/icons';
import { RoundedPictogram } from '../RoundedPictogram/RoundedPictogram';
import { Typo } from '../Typo/Typo';

interface CardWrapperProps {
  active: boolean;
  disabled?: boolean;
  size: 'L' | 'M' | 'S';
}

const avaiableSizes = {
  S: { width: '135px', height: '152px' },
  M: { width: '160px', height: '169px' },
  L: { width: '200px', height: '204px' },
};

const TickCardWrapper = styled(Elevation)<CardWrapperProps>`
  height: ${({ size }): CSSProp => avaiableSizes[size].height};
  width: ${({ size }): CSSProp => avaiableSizes[size].width};
  display: flex;
  align-items: baseline;
  position: relative;
  border: 1px solid transparent;
  transition: border 0.2s ease;

  ${({ disabled }): CSSProp =>
    disabled
      ? ''
      : css`
          &:hover {
            cursor: pointer;
            border: 1px solid ${nnColors.orangeMedium};
          }
        `}

  svg path {
    stroke-width: 1.5;
    ${({ disabled }): CSSProp =>
      disabled
        ? css`
            stroke: ${nnColors.greyLight};
          `
        : ''}
  }

  ${({ active }): CSSProp =>
    (active &&
      css`
        border: 1px solid ${nnColors.orangeMedium};
      `) ||
    ''}

  ${({ disabled }): CSSProp =>
    (disabled &&
      css`
        background: ${nnColors.snowWhite};
        border: 1px solid ${nnColors.snowWhite};
        &:hover {
          cursor: not-allowed;
        }
      `) ||
    ''}
`;

const TickCardBody = styled(Grid)`
  text-align: center;
  flex-direction: column;
  z-index: 1;
`;

const CheckboxWrapper = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 0;
`;

export interface CardProps {
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
  icon: keyof typeof icons;
  text: React.ReactNode;
  size?: 'L' | 'M' | 'S';
  isCheckbox?: boolean;
}

// eslint-disable-next-line complexity
export const TickCard: FC<CardProps> = ({
  active,
  disabled,
  onClick,
  icon,
  text,
  isCheckbox = true,
  size = 'M',
}) => {
  const [hover, setHover] = useState(false);
  const onMouseLeave = useCallback(() => setHover(false), []);
  const onMouseOver = useCallback(() => setHover(true), []);
  return (
    <TickCardWrapper
      active={active}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      shadowColor={
        active || hover
          ? convertHexToRgba('orangeMedium', '0.148805')
          : undefined
      }
      shadowSize={disabled ? undefined : 'M'}
      size={size}
    >
      <TickCardBody
        align
        center
        paddingsHorizontal="L"
        paddingsVertical="M"
        vertical
      >
        <RoundedPictogram
          background={
            (disabled && 'white') ||
            (active || hover ? 'orangeMedium' : 'snowWhite')
          }
          icon={icon}
          iconColor={(active || hover) && !disabled ? 'white' : 'greyDark'}
          iconSize={size === 'L' ? 'xl' : 'l'}
        />
        <MarginsWrapper marginTop="M" />
        <Typo
          $color={
            (disabled && 'greyLight') ||
            (active || hover ? 'orangeMedium' : 'greyDark')
          }
          $type={(active || hover) && !disabled ? 'bodyBold' : 'H6'}
          noMargin
        >
          {text}
        </Typo>
      </TickCardBody>
      {isCheckbox && (
        <CheckboxWrapper>
          <Checkbox
            disabled={disabled}
            onChange={(): void => {}}
            value={active}
          />
        </CheckboxWrapper>
      )}
    </TickCardWrapper>
  );
};
