export const primaryColors = {
  // orange
  orangeDark: '#E64415',
  orangeMedium: '#EA650D',
  orangeLight: '#EE7F00',
  // grey
  greyDark: '#414141',
  greyMedium: '#767676',
  greyLight: '#BDBDBD',
  greyWhite: '#E0E0E0',
  // white
  white: 'white',
  snowWhite: '#f2f2f2',
  offWhite: '#F7F7F7',
};

export const secondaryColors = {
  // blue
  blueDark: '#5389C2',
  blueMedium: '#7CAAD6',
  blueLight: '#A4CCEA',
  // green
  greenDark: '#5B9853',
  greenMedium: '#96B439',
  greenLight: '#C8CD2E',
  // violet
  violetDark: '#706CB0',
  violetMedium: '#9C91C6',
  violetLight: '#C6BFE0',
  // yellow
  alertYellow: '#F4AA1C',
};

export const secondarySuplement = {
  red: '#D70000',
  orangeGradient:
    'linear-gradient(270deg, #E64415 0%, #EA650D 51.13%, #EE7F00 100%)',
};

export const nnGroupColors = {
  // blue
  colourBlue700: '#004BBE',
  colourBlue500: '#336FCB',
  colourBlue300: '#6693D8',
  colourBlue100: '#99B7E5',
  colourBlue00: '#CCDBF2',
  // green
  colourGreen700: '#347663',
  colourGreen500: '#5AA896',
  colourGreen300: '#7EBEAF',
  colourGreen100: '#A7D3CA',
  colourGreen00: '#D2E9E4',
  // purple
  colourPurple700: '#35219B',
  colourPurple500: '#796ABB',
  colourPurple300: '#9B91CC',
  colourPurple100: '#BDB6DD',
  colourPurple00: '#E1DDEF',
};

export const nnColors = {
  ...primaryColors,
  ...secondaryColors,
  ...secondarySuplement,
  ...nnGroupColors,
};
