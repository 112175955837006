/* eslint-disable import/no-extraneous-dependencies */
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors, primaryColors } from '../../colors';

export type Types =
  | 'body'
  | 'bodyBold'
  | 'caption'
  | 'captionBold'
  | 'H1'
  | 'H2'
  | 'H3'
  | 'H4'
  | 'H5'
  | 'H6'
  | 'overline'
  | 'overlineBold';

export interface TypoProps {
  $type: Types;
  noMargin?: boolean;
  inline?: boolean;
  $color?: keyof typeof nnColors;
}

export const getTypoStyle = ({ $type }: TypoProps): CSSProp => {
  switch ($type) {
    case 'H1':
      return css`
        font-size: 2.5rem;
        font-family: NNDagnyDisplay, sans-serif;
        line-height: 2.75rem;
        margin-bottom: 1.5rem;
        font-weight: normal;
      `;
    case 'H2':
      return css`
        font-size: 2rem;
        font-family: NNDagnyDisplay, sans-serif;
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
        font-weight: normal;
      `;
    case 'H3':
      return css`
        font-size: 1.75rem;
        font-family: NNDagnyDisplay, sans-serif;
        line-height: 2.25rem;
        margin-bottom: 1rem;
        font-weight: normal;
      `;
    case 'H4':
      return css`
        font-size: 1.25rem;
        font-family: NNDagnyDisplay, sans-serif;
        line-height: 1.75rem;
        margin-bottom: 1rem;
        font-weight: normal;
      `;
    case 'H5':
      return css`
        font-size: 1.25rem;
        font-family: NNDagnytext, sans-serif;
        line-height: 1.75rem;
        margin-bottom: 0.5rem;
        font-weight: normal;
      `;
    case 'H6':
      return css`
        font-size: 1rem;
        font-family: NNDagnyText, sans-serif;
        line-height: 1.25rem;
        margin-bottom: 0.5rem;
        font-weight: normal;
      `;
    case 'caption':
      return css`
        font-size: 0.75rem;
        font-family: NNDagnyText, sans-serif;
        line-height: 1rem;
        margin-bottom: 0;
        font-weight: normal;
      `;
    case 'captionBold':
      return css`
        font-size: 0.75rem;
        font-family: NNDagnyDisplay, sans-serif;
        line-height: 1rem;
        margin-bottom: 0;
        font-weight: normal;
      `;
    case 'overline':
      return css`
        font-size: 0.623rem;
        font-family: NNDagnyText, sans-serif;
        line-height: 0.75rem;
        margin-bottom: 0;
        font-weight: normal;
        text-transform: uppercase;
      `;
    case 'overlineBold':
      return css`
        font-size: 0.623rem;
        font-family: NNDagnyDisplay, sans-serif;
        line-height: 0.75rem;
        margin-bottom: 0;
        font-weight: normal;
        text-transform: uppercase;
      `;
    case 'bodyBold':
      return css`
        font-size: 1rem;
        font-family: NNDagnyDisplay, sans-serif;
        line-height: 1.25rem;
        margin-bottom: 1rem;
        font-weight: normal;
      `;
    default:
      return css`
        font-size: 1rem;
        font-family: NNDagnyText, sans-serif;
        line-height: 1.25rem;
        margin-bottom: 1rem;
        font-weight: normal;
      `;
  }
};

export const Typo = styled.p<TypoProps>`
  margin: 0;
  color: ${primaryColors.greyDark};
  ${getTypoStyle}
  ${({ noMargin }): CSSProp =>
    noMargin
      ? css`
          margin: 0;
        `
      : ''}
  ${({ inline }): CSSProp =>
    inline
      ? css`
          display: inline;
        `
      : ''}
  ${({ $color }): CSSProp =>
    $color
      ? css`
          color: ${nnColors[$color]};
        `
      : ''}
`;

const baseLinkStyle = ({
  inline,
  noMargin,
  $color,
}: LinkTypoProps): CSSProp => css`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${getTypoStyle({ $type: 'bodyBold' })}
  ${(): CSSProp =>
    noMargin
      ? css`
          margin: 0;
        `
      : ''}
  ${(): CSSProp =>
    inline
      ? css`
          display: inline;
        `
      : ''}

  ${(): CSSProp =>
    $color
      ? css`
          color: ${nnColors[$color]};
        `
      : ''}
`;

export type LinkTypoProps = Omit<TypoProps, '$type'>;

export const LinkTypo = styled.a<LinkTypoProps>`
  color: ${nnColors.orangeMedium};
  &:visited {
    color: ${nnColors.orangeDark};
  }
  ${({ noMargin, inline, $color }): CSSProp =>
    baseLinkStyle({ noMargin, inline, $color })}
`;

export const LinkTypoTetiary = styled.a<LinkTypoProps>`
  color: ${nnColors.greyDark};
  &:visited {
    color: ${nnColors.greyMedium};
  }
  ${getTypoStyle({ $type: 'bodyBold' })}
  ${({ noMargin, inline, $color }): CSSProp =>
    baseLinkStyle({ noMargin, inline, $color })}
`;
