import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors } from '../../colors';
import { ElementFormWrapperHorizontal } from '../Form/ElementFormWrapperHorizontal';

interface RadioViewProps {
  disabled?: boolean;
  error?: boolean;
}

interface RadioTickProps {
  checked: boolean;
  disabled?: boolean;
  error?: boolean;
}

const RadioView = styled.input<RadioViewProps>`
  appearance: none;
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  border: 1px solid;
  background: white;
  margin: 0;
  border-radius: 50%;
  padding: 0;
  flex-shrink: 0;
  cursor: pointer;
  ${({ disabled }): CSSProp =>
    disabled
      ? css`
          border-color: ${nnColors.greyWhite};
          background: ${nnColors.greyWhite};
          cursor: not-allowed;
        `
      : css`
          border-color: ${nnColors.greyLight};
          background: white;
          cursor: pointer;
          &:hover {
            border-color: ${nnColors.orangeMedium};
            box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
          }
        `}
  ${({ error }): CSSProp =>
    (error &&
      css`
        border-color: ${nnColors.red};
      `) ||
    ''}
`;

const RadioTick = styled.div<RadioTickProps>`
  position: absolute;
  left: 8px;
  top: 8px;
  width: 8px;
  height: 8px;
  transition: opacity 150ms;
  background: ${nnColors.orangeMedium};
  border-radius: 50%;
  pointer-events: none;

  ${({ error }): CSSProp =>
    (error &&
      css`
        background: ${nnColors.red};
      `) ||
    ''}

  ${({ disabled }): CSSProp =>
    (disabled &&
      css`
        background: ${nnColors.greyLight};
      `) ||
    ''}

  ${({ checked }): CSSProp =>
    checked
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

export interface RadioProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'disabled' | 'name' | 'onChange'
  > {
  id?: string;
  onChange: () => void;
  disabled?: boolean;
  label?: React.ReactNode;
  error?: string;
  isInvalid?: boolean;
  checked?: boolean;
  noMargin?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
  label,
  disabled,
  checked,
  error,
  isInvalid,
  onChange,
  noMargin,
  ...props
}) => (
  <ElementFormWrapperHorizontal
    disabled={disabled}
    error={error}
    label={label}
    noMargin={noMargin}
  >
    <RadioView
      checked={checked}
      disabled={disabled}
      error={!!error || isInvalid}
      onChange={() => (disabled ? undefined : onChange())}
      type="radio"
      {...props}
    />
    <RadioTick checked={!!checked} disabled={disabled} error={!!error} />
  </ElementFormWrapperHorizontal>
);
