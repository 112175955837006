import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { MarginsWrapper } from '../Margins/Margins';
import { RoundedPictogram } from '../RoundedPictogram/RoundedPictogram';
import { Typo } from '../Typo/Typo';

const CaptionFieldWrapper = styled.span<{ caption: boolean }>`
  align-items: center;
  margin-top: 6px;
  ${({ caption }): CSSProp =>
    (caption &&
      css`
        display: flex;
      `) ||
    css`
      display: none;
    `}
`;

export const CaptionField: React.FC<{ caption?: string }> = ({
  caption,
  ...props
}) => (
  <CaptionFieldWrapper {...props} caption={!!caption}>
    <RoundedPictogram
      background="greyLight"
      icon="info"
      iconColor="white"
      iconSize="xs"
    />
    <MarginsWrapper marginLeft="XS">
      <Typo $color="greyMedium" $type="caption">
        {caption}
      </Typo>
    </MarginsWrapper>
  </CaptionFieldWrapper>
);
