/* eslint-disable react/no-multi-comp */
import type React from 'react';
import type { LinkHTMLAttributes } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

import { MarginsWrapper } from '../Margins/Margins';
import type { Props as PictogramProps } from '../Pictogram/Pictogram';
import { Pictogram } from '../Pictogram/Pictogram';
import { Typo } from '../Typo/Typo';
import { iconLinkStyles } from './styled';

const StyledPlainLink = styled.a<
  LinkHTMLAttributes<'a'> & { disabled?: boolean }
>`
  ${({ disabled }): FlattenSimpleInterpolation => iconLinkStyles(disabled)}
`;

export interface IconLinkPlainProps {
  text: string;
  disabled?: boolean;
  href?: string;
  target?: string;
  iconDirection?: 'left' | 'right';
}

const IconLinkPlainWrapper: React.FC<IconLinkPlainProps & PictogramProps> = ({
  text,
  href,
  target,
  children,
  iconDirection = 'left',
  ...props
}) => (
  <StyledPlainLink
    href={props.disabled ? undefined : href}
    target={target}
    {...props}
  >
    {iconDirection === 'left' && children}
    <MarginsWrapper
      marginLeft={iconDirection === 'left' ? 'S' : undefined}
      marginRight={iconDirection === 'right' ? 'S' : undefined}
    >
      <Typo $type="bodyBold" noMargin>
        {text}
      </Typo>
    </MarginsWrapper>
    {iconDirection === 'right' && children}
  </StyledPlainLink>
);

export const IconLinkPlain: React.FC<IconLinkPlainProps & PictogramProps> = ({
  text,
  href,
  target,
  icon = 'arrow-right',
  iconDirection = 'left',
  ...props
}) => (
  <IconLinkPlainWrapper
    href={href}
    iconDirection={iconDirection}
    target={target}
    text={text}
    {...props}
  >
    <Pictogram
      icon={icon}
      iconColor={props.disabled ? 'greyLight' : 'orangeMedium'}
    />
  </IconLinkPlainWrapper>
);
