/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';
import { useOutsideClickRef } from 'rooks';
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors, primaryColors, secondarySuplement } from '../../colors';
import { ElementFormWrapper } from '../Form/ElementFormWrapper';
import { Pictogram } from '../Pictogram/Pictogram';
import { OptionsList } from './OptionsList';

interface SelectButtonProps {
  showInvalid?: boolean;
  disabled?: boolean;
  dense?: boolean;
  _extremeDense?: boolean;
}

export const SelectButton = styled.button<SelectButtonProps>`
  border: 1px solid ${primaryColors.greyLight};
  color: ${primaryColors.greyDark};
  background: white;
  text-align: left;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
  font-family: NNDagnyText;
  font-size: 16px;
  width: 100%;
  transition: border 150ms ease-in;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ dense }): CSSProp =>
    dense
      ? css`
          height: 48px;
          padding: 14px 44px 14px 16px;
        `
      : css`
          height: 60px;
          padding: 30px 44px 10px 16px;
        `}

  ${({ _extremeDense }): CSSProp =>
    (_extremeDense &&
      css`
        height: 32px;
        padding: 6px 44px 6px 16px;
      `) ||
    ''}

  ${({ disabled }): CSSProp =>
    (disabled &&
      css`
        pointer-events: none;
        border-color: ${primaryColors.greyLight};
        color: ${primaryColors.greyLight};
        background: ${primaryColors.snowWhite};
        cursor: not-allowed;

        svg path {
          stroke: ${nnColors.greyLight};
        }
      `) ||
    css`
      &:hover,
      &:focus {
        border-color: ${primaryColors.orangeMedium};
        box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
      }
    `}

  ${({ showInvalid }): CSSProp =>
    (showInvalid &&
      css`
        border-color: ${secondarySuplement.red};
        &:focus {
          border-color: ${secondarySuplement.red};
          box-shadow: none;
        }
      `) ||
    css``}
`;

interface SelectArrowWrapperProps {
  isOpen: boolean;
  dense?: boolean;
  _extremeDense?: boolean;
}

const SelectArrowWrapper = styled.div<SelectArrowWrapperProps>`
  position: absolute;
  right: 12px;
  top: 18px;
  transition: transform 150ms ease-in, top 150ms ease-in;

  ${({ dense }): CSSProp =>
    dense
      ? css`
          top: 13px;
        `
      : css`
          top: 18px;
        `}

  ${({ isOpen, dense }): CSSProp =>
    (isOpen &&
      dense &&
      css`
        transform: rotate(180deg);
      `) ||
    ''}

  ${({ isOpen, dense }): CSSProp =>
    (isOpen &&
      !dense &&
      css`
        transform: rotate(180deg);
      `) ||
    ''}

  ${({ _extremeDense }): CSSProp =>
    (_extremeDense &&
      css`
        top: 4px;
      `) ||
    ''}
  ${({ isOpen, _extremeDense }): CSSProp =>
    (isOpen &&
      _extremeDense &&
      css`
        top: 4px;
      `) ||
    ''}
`;

export interface IOption<T> {
  value: T;
  option?: string;
}

export interface SelectProps<T, R>
  extends Omit<
    React.InputHTMLAttributes<HTMLButtonElement>,
    'disabled' | 'name' | 'onBlur' | 'onChange' | 'type' | 'value'
  > {
  options: IOption<T>[];
  value?: R;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  onInValid?: (_: boolean) => void;
  label?: string;
  dense?: boolean;
  noMargin?: boolean;
  _extremeDense?: boolean;
  onChange: (value: R) => void;
  onBlur?: () => void;
}

// eslint-disable-next-line react/function-component-definition, func-style, max-statements
export function Select<T, R>({
  options,
  value,
  error,
  required,
  label,
  onChange,
  onBlur,
  disabled,
  onInValid,
  dense,
  noMargin,
  _extremeDense,
  ...props
}: SelectProps<T, R>): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean | undefined>();
  const [blurred, setIsBlurred] = useState<boolean>(false);
  const [focused, setIsFocused] = useState<boolean>(false);

  const arrayValue = Array.isArray(value)
    ? (value as T[])
    : [value as unknown as T];

  const handleOnBlur = useCallback((): void => {
    setIsFocused(false);
    setIsBlurred(true);
    setIsOpen(undefined);
    if (isOpen && onBlur) {
      onBlur();
    }
  }, [isOpen, onBlur]);

  const [spanRef] = useOutsideClickRef(handleOnBlur);

  const selectedOptions = options
    .filter((option) => arrayValue.includes(option.value))
    .map((option) => option.option || option.value)
    .join(', ');

  const isEmptyValue = arrayValue.length === 0 || !selectedOptions;

  // invalid
  let isInvalid = false;
  let isInvalidAndBlurred = false;

  if (required && isEmptyValue) {
    // when required blurred and not filled
    isInvalid = true;
  } else if (error) {
    // when error from parent is given
    isInvalid = true;
  }

  if (blurred) {
    isInvalidAndBlurred = isInvalid;
  }

  useEffect(() => onInValid?.(isInvalid), [isInvalid, onInValid]);

  return (
    <span ref={spanRef}>
      <ElementFormWrapper
        cursor="pointer"
        dense={dense}
        disabled={disabled}
        error={error}
        filledUp={!(focused || !isEmptyValue)}
        label={label}
        noMargin={noMargin}
        required={required}
        showInvalid={!!error || isInvalidAndBlurred}
      >
        <SelectButton
          _extremeDense={_extremeDense}
          dense={dense}
          disabled={disabled}
          onClick={(): void => (!disabled ? setIsOpen(!isOpen) : undefined)}
          showInvalid={!!error || isInvalidAndBlurred}
          type="button"
          {...props}
        >
          {!isEmptyValue ? selectedOptions : undefined}
          <SelectArrowWrapper
            _extremeDense={_extremeDense}
            dense={dense}
            isOpen={!!isOpen}
          >
            <Pictogram icon="arrow-down" />
          </SelectArrowWrapper>
        </SelectButton>
        <OptionsList<T, R>
          _extremeDense={_extremeDense}
          dense={dense}
          isMulti={Array.isArray(value)}
          isOpen={isOpen}
          onChange={onChange}
          options={options}
          setIsOpen={setIsOpen}
          value={value}
        />
      </ElementFormWrapper>
    </span>
  );
}
