import styled, { keyframes } from 'styled-components';

import { Pictogram } from '../Pictogram/Pictogram';

const LoaderAnimation = keyframes`
  0% {transform: rotate(360deg);}
  100% {transform: rotate(0);}
`;

const PictogramLoader = styled(Pictogram)`
  animation: ${LoaderAnimation} 750ms infinite ease-in-out;
`;

export const LoaderWrapper = styled.div``;

export const Loader: React.FC = ({ ...props }) => (
  <LoaderWrapper {...props}>
    <PictogramLoader icon="loader" />
  </LoaderWrapper>
);
