import type { AnimationEvent, FC, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { CSSProp } from 'styled-components';
import styled, { css, keyframes } from 'styled-components';

export interface Props {
  isOpen: boolean;
  transitionOff?: boolean;
  padded?: boolean;
  children: ReactNode;
  id?: string;
  className?: string;
  onCollapseAnimated?: () => void;
  scrollOnOpen: boolean | number;
  animationTime?: 300 | 500;
}

interface CollapseBodyProps {
  transitionOff: boolean;
  isOpened: boolean;
  isOpen: boolean;
  padded?: boolean;
  scrollOnOpen?: boolean;
  animationTime?: 300 | 500;
}

const openAnimation = keyframes`
  0% {
    max-height: 0;
  }
  100% {
    max-height:  100vh;
  }
`;

const closeAnimation = keyframes`
  0% {
    max-height: 100vh;
  }
  100% {
    max-height:  0;
  }
`;

const CollapseBodyContainer = styled.div<CollapseBodyProps>`
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  ${({ padded }): CSSProp =>
    (padded &&
      css`
        padding: 0 32px;
      `) ||
    ''}

  ${({
    transitionOff,
    isOpen,
    isOpened,
    animationTime,
  }: CollapseBodyProps): CSSProp =>
    isOpen
      ? css`
          ${!transitionOff
            ? css`
                animation: ${openAnimation} ${animationTime}ms ease-in 1;
              `
            : ''}
          max-height: unset;
          ${isOpened
            ? css`
                overflow: visible;
              `
            : ''}
        `
      : css`
          ${!transitionOff
            ? css`
                animation: ${closeAnimation} ${animationTime}ms ease-out 1;
              `
            : ''}
          max-height: 0;
        `};
`;

export const CollapseBody: FC<Props> = ({
  isOpen,
  children,
  id,
  className,
  transitionOff,
  animationTime = 500,
  scrollOnOpen,
  onCollapseAnimated,
  padded,
}) => {
  const [renderContent, setRenderContent] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect((): void => {
    if (isOpen) {
      // opening
      setRenderContent(true);
      if (scrollOnOpen !== false) {
        setTimeout(() => {
          if (ref.current) {
            const offset = scrollOnOpen === true ? 0 : scrollOnOpen || 0;
            window.scrollTo({
              behavior: 'smooth',
              left: 0,
              top:
                ref.current.getBoundingClientRect().top +
                window.scrollY +
                offset,
            });
          }
        }, 500);
      }
    }

    if (transitionOff === true) {
      // no animation, already opened
      setIsOpened(isOpen);
      if (!isOpen) {
        // no animation - already closed, hide content
        setRenderContent(false);
      }
    }
  }, [isOpen, transitionOff, scrollOnOpen]);

  const onAnimationEnd = useCallback(
    (event: AnimationEvent<HTMLDivElement>): void => {
      if (
        ![openAnimation.getName(), closeAnimation.getName()].includes(
          event.animationName,
        )
      ) {
        return;
      }

      if (isOpen) {
        // opening done
        setIsOpened(true);
      } else {
        // closing done
        setRenderContent(false);
        setIsOpened(false);
      }

      if (onCollapseAnimated) {
        onCollapseAnimated();
      }
    },
    [isOpen, onCollapseAnimated],
  );

  return (
    <CollapseBodyContainer
      animationTime={animationTime}
      className={className}
      id={id}
      isOpen={isOpen}
      isOpened={isOpened}
      onAnimationEnd={onAnimationEnd}
      padded={padded}
      ref={ref}
      transitionOff={!!transitionOff}
    >
      {renderContent && children}
    </CollapseBodyContainer>
  );
};
