import type { CSSProp } from 'styled-components';
import styled from 'styled-components';

import { nnColors } from '../../colors';
import { convertHexToRgba } from '../../utils/convertHexToRgba';
import type { PaddingsProps } from '../Margins/Margins';
import { MarginsAndPaddings } from '../Margins/Margins';

export interface ElevationProps
  extends React.HTMLAttributes<HTMLDivElement>,
    PaddingsProps {
  shadowSize?: 'L' | 'M' | 'S' | 'XL' | 'XS';
  shadowColor?: string;
  color?: keyof typeof nnColors;
}

const boxShadow = (
  shadowColor?: ElevationProps['shadowColor'],
  shadowSize?: ElevationProps['shadowSize'],
): CSSProp => {
  const shadow = shadowColor || convertHexToRgba('greyDark', '0.1');
  switch (shadowSize) {
    case 'XS':
      return `0px 0px 1px ${shadow}, 0px 1px 2px ${shadow}, 0px 2px 6px ${shadow};`;
    case 'S':
      return `0px 0px 4px ${shadow}, 0px 2px 4px ${shadow}, 0px 2px 8px ${shadow};`;
    case 'M':
      return `0px 1px 4px ${shadow}, 0px 3px 10px ${shadow}, 0px 10px 20px ${shadow};`;
    case 'L':
      return `0px 0px 4px ${shadow}, 0px 4px 8px ${shadow}, 0px 8px 24px ${shadow};`;
    case 'XL':
      return `0px 0px 4px ${shadow}, 0px 8px 16px ${shadow}, 0px 16px 32px ${shadow};`;
    default:
      return `0px 1px 4px ${shadow}, 0px 3px 10px ${shadow}, 0px 10px 20px ${shadow};`;
  }
};

export const Elevation = styled(MarginsAndPaddings)<ElevationProps>`
  background-color: ${({ color }): CSSProp =>
    color ? nnColors[color] : 'white'};
  box-shadow: ${({ shadowSize, shadowColor }): CSSProp =>
    shadowSize ? boxShadow(shadowColor, shadowSize) : '0'};
  border-radius: 3px;
  box-sizing: border-box;
`;
