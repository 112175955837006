/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

export const space = {
  XS: '4px',
  S: '8px',
  M: '16px',
  L: '24px',
  LXL: '32px',
  XL: '48px',
  XXL: '64px',
};

export type SpaceSizes = keyof typeof space;

export interface MarginsProps {
  marginLeft?: SpaceSizes;
  marginRight?: SpaceSizes;
  marginTop?: SpaceSizes;
  marginBottom?: SpaceSizes;
  marginsHorizontal?: SpaceSizes;
  marginsVertical?: SpaceSizes;
}

export const MarginsWrapper = styled.div<MarginsProps>`
  margin: 0;

  margin-left: ${({ marginLeft }: MarginsProps): CSSProp =>
    marginLeft ? space[marginLeft] : ''};
  margin-right: ${({ marginRight }: MarginsProps): CSSProp =>
    marginRight ? space[marginRight] : ''};
  margin-top: ${({ marginTop }: MarginsProps): CSSProp =>
    marginTop ? space[marginTop] : ''};
  margin-bottom: ${({ marginBottom }: MarginsProps): CSSProp =>
    marginBottom ? space[marginBottom] : ''};
  ${({ marginsVertical }: MarginsProps): CSSProp =>
    (marginsVertical &&
      css`
        margin-left: ${space[marginsVertical]};
        margin-right: ${space[marginsVertical]};
      `) ||
    ''};

  ${({ marginsHorizontal }: MarginsProps): CSSProp =>
    (marginsHorizontal &&
      css`
        margin-top: ${space[marginsHorizontal]};
        margin-bottom: ${space[marginsHorizontal]};
      `) ||
    ''};
`;

export interface PaddingsProps {
  paddingLeft?: SpaceSizes;
  paddingRight?: SpaceSizes;
  paddingTop?: SpaceSizes;
  paddingBottom?: SpaceSizes;
  paddingsHorizontal?: SpaceSizes;
  paddingsVertical?: SpaceSizes;
}

export const PaddingsWrapper = styled.div<PaddingsProps>`
  padding-left: ${({ paddingLeft }: PaddingsProps): CSSProp =>
    paddingLeft ? space[paddingLeft] : ''};
  padding-right: ${({ paddingRight }: PaddingsProps): CSSProp =>
    paddingRight ? space[paddingRight] : ''};
  padding-top: ${({ paddingTop }: PaddingsProps): CSSProp =>
    paddingTop ? space[paddingTop] : ''};
  padding-bottom: ${({ paddingBottom }: PaddingsProps): CSSProp =>
    paddingBottom ? space[paddingBottom] : ''};
  ${({ paddingsVertical }: PaddingsProps): CSSProp =>
    (paddingsVertical &&
      css`
        padding-left: ${space[paddingsVertical]};
        padding-right: ${space[paddingsVertical]};
      `) ||
    ''};

  ${({ paddingsHorizontal }: PaddingsProps): CSSProp =>
    (paddingsHorizontal &&
      css`
        padding-top: ${space[paddingsHorizontal]};
        padding-bottom: ${space[paddingsHorizontal]};
      `) ||
    ''};
`;

interface SpacerProps {
  size: SpaceSizes;
}

export const Spacer = styled.div`
  display: inline-block;
  ${({ size }: SpacerProps): CSSProp => css`
    width: ${space[size]};
    height: ${space[size]};
    min-width: ${space[size]};
    max-width: ${space[size]};
    min-height: ${space[size]};
    max-height: ${space[size]};
  `}
`;

export const MarginsAndPaddings = PaddingsWrapper.withComponent(MarginsWrapper);
