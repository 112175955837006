import styled from 'styled-components';

import { MarginsWrapper } from '../Margins/Margins';

export const ButtonWrapper = styled(MarginsWrapper.withComponent('button'))`
  border: none;
  background: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;
