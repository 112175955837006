/* eslint-disable react/no-multi-comp */
import type { nnColors, primaryColors } from '../../colors';
import { Typo } from '../Typo/Typo';
import { Bullet, IconClose, IconOk, StyledChip } from './Chip.styled';

export interface ChipLabelProps {
  bColor?: keyof typeof nnColors;
  color?: keyof typeof primaryColors;
  dense?: boolean;
  className?: string;
  smallCaps?: boolean;
  disabled?: boolean;
  children: string;
  id?: string;
  as?: React.ElementType;
}

export interface FilterChipProps extends ChipLabelProps {
  onClick?: () => void;
  value?: string;
}

export interface ChipProps extends ChipLabelProps {
  onClick?: () => void;
  selected?: boolean;
}

export const ChipButton: React.FC<ChipProps> = ({
  children,
  smallCaps,
  bColor,
  dense,
  onClick,
  selected,
  disabled,
  ...props
}) => (
  <StyledChip
    {...props}
    $color="white"
    $type="caption"
    bColor={bColor}
    dense={dense}
    disabled={disabled}
    onClick={disabled ? () => {} : onClick}
    selected={selected}
    smallCaps={smallCaps}
    variant="button"
  >
    <Bullet
      bColor={bColor}
      dense={dense}
      disabled={disabled}
      selected={selected}
    />
    {smallCaps ? children.toLowerCase() : children}
    <IconClose color="white" />
    <IconOk color="white" />
  </StyledChip>
);

export const FilterChip: React.FC<FilterChipProps> = ({
  children,
  smallCaps,
  bColor = 'greyWhite',
  dense,
  color = 'greyDark',
  value,
  onClick,
  ...props
}) => (
  <StyledChip
    {...props}
    $color={color}
    $type="caption"
    bColor={bColor}
    dense={dense}
    onClick={onClick}
    selected
    smallCaps={smallCaps}
    variant="filter"
  >
    {children}
    {value && (
      <Typo $color="greyDark" $type="captionBold">
        &nbsp;
        {value}
      </Typo>
    )}
    <IconClose color="orangeMedium" />
  </StyledChip>
);

export const Chip: React.FC<ChipLabelProps> = ({
  children,
  smallCaps,
  bColor,
  dense,
  color = 'white',
  ...props
}) => (
  <StyledChip
    {...props}
    $color={color}
    $type={dense ? 'overline' : 'caption'}
    bColor={bColor}
    dense={dense}
    isCircle={children.length === 1}
    smallCaps={smallCaps}
    variant="label"
  >
    {smallCaps ? children.toLowerCase() : children}
  </StyledChip>
);
