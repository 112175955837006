import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { primaryColors } from '../../colors';
import { LoaderWrapper } from '../Loader/Loader';

export type Types = 'primary' | 'secondary' | 'tertiary';
export type Sizes = 'l' | 'm' | 's';

export interface ButtonBaseProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $type?: Types;
  $size?: Sizes;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const hover = css`
  &:hover,
  &:active {
    color: white;
    background: ${primaryColors.orangeDark};
    box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
  }
`;

export const ButtonBase = styled.button<ButtonBaseProps>`
  font-family: NNDagnyDisplay;
  font-size: 18px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: background-color 100ms, border 100ms, color 100ms;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }): CSSProp => (disabled ? 'not-allowed' : 'pointer')};

  ${({ $type, disabled }): CSSProp =>
    $type === 'secondary' && disabled
      ? css`
          background-color: ${primaryColors.snowWhite};
          border: 1px solid ${primaryColors.greyLight};
          color: ${primaryColors.greyLight};
        `
      : ''}

  ${({ $type, disabled }): CSSProp =>
    $type === 'secondary' && !disabled
      ? css`
          background-color: white;
          border: 1px solid ${primaryColors.orangeMedium};
          color: ${primaryColors.orangeMedium};
          ${hover}
        `
      : ''}

  ${({ $type, disabled }): CSSProp =>
    $type === 'tertiary' && disabled
      ? css`
          background: transparent;
          color: ${primaryColors.greyLight};
        `
      : ''}

  ${({ $type, disabled }): CSSProp =>
    $type === 'tertiary' && !disabled
      ? css`
          background: transparent;
          color: ${primaryColors.orangeMedium};

          &:hover,
          &:active,
          &:focus {
            border: 1px solid ${primaryColors.orangeMedium};
            filter: drop-shadow(0px 5px 20px rgba(240, 128, 0, 0.148805));
          }
        `
      : ''}

  ${({ $type, disabled }): CSSProp =>
    $type === 'primary' && disabled
      ? css`
          background-color: ${primaryColors.greyWhite};
          color: ${primaryColors.greyLight};
        `
      : ''}

  ${({ $type, disabled }): CSSProp =>
    $type === 'primary' && !disabled
      ? css`
          color: white;
          background-color: ${primaryColors.orangeMedium};
          ${hover}
        `
      : ''}

  ${({ loading }): CSSProp =>
    loading
      ? css`
          &,
          &:hover,
          &:active,
          &:focus {
            border: 1px solid ${primaryColors.greyWhite};
            background-color: ${primaryColors.greyWhite};
            cursor: progress;
            color: transparent;
            user-select: none;
            box-shadow: none;
            user-select: none;
            display: flex;
            justify-content: center;
          }
          ${LoaderWrapper} {
            position: absolute;
          }
        `
      : ''}
`;
