import type { ComponentProps, ReactNode } from 'react';
import type React from 'react';
import type { CSSProp } from 'styled-components';
import styled from 'styled-components';

import { nnColors } from '../../colors';
import { Grid } from '../Grid/Grid';
import { Pictogram } from '../Pictogram/Pictogram';
import { Typo } from '../Typo/Typo';

export interface IndicatorBadgeProps
  extends Pick<ComponentProps<typeof Pictogram>, 'icon'> {
  content?: number | string;
  badge?: ReactNode;
  position?: 'bottom' | 'top';
}

const IndicatorBadgeWrapper = styled.span`
  display: inline-block;
  position: relative;
`;

export const IndicatorBadgeIcon = styled(Grid)<{
  borderLess: boolean;
  position: 'bottom' | 'top';
}>`
  position: absolute;
  background: white;
  ${({ position }): CSSProp =>
    position === 'top' ? 'top: -5px;' : 'bottom: -5px;'}
  right: -5px;
  min-height: 20px;
  min-width: 20px;
  border: ${({ borderLess }): CSSProp | number =>
    borderLess ? 0 : `1px solid ${nnColors.orangeMedium}`};
  ${({ borderLess }): CSSProp =>
    borderLess ? '' : 'box-shadow: 0 0 0 1px white;'};
  border-radius: 50%;
  padding: 0;
`;

export const IndicatorBadge: React.FC<IndicatorBadgeProps> = ({
  children,
  content,
  icon,
  badge,
  position = 'top',
}) => (
  <IndicatorBadgeWrapper>
    {children}
    {(icon || badge || content) && (
      <IndicatorBadgeIcon
        align
        borderLess={!content && !!(icon || badge)}
        center
        position={position}
      >
        {content && (
          <Typo $color="orangeMedium" $type="captionBold" noMargin>
            {content}
          </Typo>
        )}
        {!content && icon && <Pictogram icon={icon} iconSize="m" />}
        {!content && !icon && badge}
      </IndicatorBadgeIcon>
    )}
  </IndicatorBadgeWrapper>
);
