import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { Loader } from '../Loader/Loader';
import type { ButtonBaseProps, Sizes } from './ButtonBase';
import { ButtonBase } from './ButtonBase';

const getCSSforSize = ($size?: Sizes): FlattenSimpleInterpolation => {
  if ($size === 's') {
    return css`
      height: 24px;
      padding: 2px 24px;
    `;
  }
  if ($size === 'm') {
    return css`
      height: 32px;
      padding: 5px 24px;
    `;
  }

  return css`
    height: 48px;
    padding: 13px 24px;
  `;
};

export const StyledButton = styled(ButtonBase)`
  ${({ $size }): FlattenSimpleInterpolation => getCSSforSize($size)};
`;

export const Button: React.FC<ButtonBaseProps> = ({
  children,
  disabled,
  $size,
  $type,
  loading,
  onClick,
  ...props
}) => (
  <StyledButton
    {...props}
    $size={$size}
    $type={$type}
    disabled={loading || disabled}
    loading={loading}
    onClick={!loading ? onClick : undefined}
  >
    {loading && <Loader />}
    {children}
  </StyledButton>
);
