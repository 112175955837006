import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { Grid } from '../Grid/Grid';
import LogoIcons from './nnLogo.png';
import NnText from './nnText.svg';

export type LogoSizes = 'l' | 'm' | 's';
export type LogoTypes = 'basic' | 'full';

export const scales: { [key in LogoSizes]: number } = {
  s: 0.5,
  m: 0.6667,
  l: 1,
};

export const sizes: { [key in LogoSizes]: number } = {
  s: 24,
  m: 32,
  l: 48,
};

const basicNnTextWidth = 100;
const basicNnTextHeight = 31;

export interface LogoProps {
  type?: LogoTypes;
  size?: LogoSizes;
}

const IconStyles = css<{ size: LogoSizes }>`
  svg {
    position: absolute;
    left: 0;
    right: 0;
    transform: ${({ size }): CSSProp => `scale(${scales[size as LogoSizes]})`};
    transform-origin: 0 0;
  }
`;

const LogoImg = styled.img<{ size: LogoSizes }>`
  height: ${({ size }): CSSProp => `${sizes[size]}px`};
  width: ${({ size }): CSSProp => `${sizes[size]}px`};
`;

const NNTextWrapper = styled.div<{ size: LogoSizes }>`
  margin-left: 9px;
  overflow: hidden;
  position: relative;
  min-width: ${({ size }): CSSProp =>
    `${basicNnTextWidth * scales[size as LogoSizes]}px`};
  max-width: ${({ size }): CSSProp =>
    `${basicNnTextWidth * scales[size as LogoSizes]}px`};
  min-height: ${({ size }): CSSProp =>
    `${basicNnTextHeight * scales[size as LogoSizes]}px`};
  max-height: ${({ size }): CSSProp =>
    `${basicNnTextHeight * scales[size as LogoSizes]}px`};
  ${IconStyles};
`;

export const Logo: React.VFC<LogoProps> = ({ type = 'full', size = 'l' }) => (
  <Grid align>
    <LogoImg alt="logo" size={size} src={LogoIcons} />
    {type === 'full' && (size === 'l' || size === 'm') && (
      <NNTextWrapper size={size}>
        <NnText />
      </NNTextWrapper>
    )}
  </Grid>
);
