/* eslint-disable import/no-extraneous-dependencies */
import { createGlobalStyle } from 'styled-components';

import NNDagnyDisplay from '../assets/fonts/NNDagnyDisplayWebPro.woff';
import NNDagnyTextWeb from '../assets/fonts/NNDagnyTextWeb.woff';

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: NNDagnyText;
    src: url(${NNDagnyTextWeb});
    letter-spacing: 5px;
  }
  @font-face {
    font-family: NNDagnyDisplay;
    src: url(${NNDagnyDisplay});
    letter-spacing: 5px;
  }
  button {
    font-weight: normal;
  }
`;
