import type React from 'react';
import type { CSSProp } from 'styled-components';
import styled from 'styled-components';

import { nnColors } from '../../colors';
import { Grid } from '../Grid/Grid';
import { IconLinkPlain } from '../Links/IconLinkPlain';
import { Logo } from '../Logo/Logo';
import { MarginsWrapper, PaddingsWrapper } from '../Margins/Margins';
import { LinkTypoTetiary, Typo } from '../Typo/Typo';

const FooterPaddings = styled(PaddingsWrapper)`
  position: sticky;
  bottom: 0;
  width: 100%;
  background: ${nnColors.white};

  @media (max-width: 896px) {
    padding: 24px 16px 32px 16px;
  }
`;

const FooterRow = styled(Grid)<{ margin?: string }>`
  margin-bottom: ${({ margin }): CSSProp => (margin && margin) || ''};
  @media (max-width: 896px) {
    &:nth-child(2) {
      align-items: flex-start;
      flex-direction: column;
    }
  }
`;

const PhoneWrapper = styled(Grid)`
  white-space: pre;
  margin-bottom: 8px;

  @media (max-width: 896px) {
    align-items: flex-start;
    flex-direction: column;
    margin: 8px 0 8px 0;
  }
`;

const PhoneNumberBreak = styled.span`
  margin: 0 12px;
  ::after {
    content: '•';
    font-size: 2rem;
    line-height: 0;
    color: #ea650d;
    position: relative;
    top: 5px;
  }
`;

const FooterLinkTypoTetiary = styled(LinkTypoTetiary)`
  white-space: nowrap;
`;

const LinksWrapper = styled(Grid)`
  @media screen and (max-width: 545px) {
    flex-direction: column;
  }
`;

export interface FooterProps {
  copyrightYear?: string;
  regulationsUrl?: string;
  privacyPolicyUrl?: string;
  securityUrl?: string;
  hideCustomerService?: boolean;
}

export const Footer: React.VFC<FooterProps> = ({
  copyrightYear = '2022',
  regulationsUrl,
  privacyPolicyUrl,
  securityUrl,
  hideCustomerService,
}) => (
  <FooterPaddings paddingsHorizontal="LXL" paddingsVertical="XL">
    <FooterRow align justify="space-between" margin="16px">
      <Logo size="m" />
      <IconLinkPlain
        href="https://nn.pl"
        icon="arrow-right"
        target="_blank"
        text="nn.pl"
      />
    </FooterRow>
    {(!hideCustomerService || regulationsUrl) && (
      <FooterRow align justify="space-between" margin="8px">
        <LinksWrapper wrap>
          {regulationsUrl && (
            <MarginsWrapper marginBottom="S" marginRight="M">
              <FooterLinkTypoTetiary
                $color="greyDark"
                href={regulationsUrl}
                noMargin
              >
                Regulamin
              </FooterLinkTypoTetiary>
            </MarginsWrapper>
          )}
          {privacyPolicyUrl && (
            <MarginsWrapper marginBottom="S" marginRight="M">
              <FooterLinkTypoTetiary
                $color="greyDark"
                href={privacyPolicyUrl}
                noMargin
              >
                Polityka prywatności
              </FooterLinkTypoTetiary>
            </MarginsWrapper>
          )}
          {securityUrl && (
            <MarginsWrapper marginBottom="S" marginRight="M">
              <FooterLinkTypoTetiary
                $color="greyDark"
                href={securityUrl}
                noMargin
              >
                Bezpieczeństwo
              </FooterLinkTypoTetiary>
            </MarginsWrapper>
          )}
        </LinksWrapper>
        {!hideCustomerService && (
          <PhoneWrapper align justify="flex-end">
            <MarginsWrapper marginRight="S">
              <Typo $color="greyMedium" $type="H6" noMargin>
                Obsługa klienta:
              </Typo>
            </MarginsWrapper>
            <MarginsWrapper marginRight="S">
              <span>
                <LinkTypoTetiary
                  $color="greyMedium"
                  href="tel: +48 801 20 30 40"
                  noMargin
                >
                  +48 801 20 30 40
                </LinkTypoTetiary>
                <PhoneNumberBreak />
                <LinkTypoTetiary
                  $color="greyMedium"
                  href="tel: +48 22 522 71 24"
                  noMargin
                >
                  +48 22 522 71 24
                </LinkTypoTetiary>
              </span>
            </MarginsWrapper>
            <Typo $color="greyMedium" $type="H6" noMargin>
              czynna pn. - pt., godz. 8-20
            </Typo>
          </PhoneWrapper>
        )}
      </FooterRow>
    )}
    <FooterRow justify="space-between">
      <Typo $color="greyMedium" $type="captionBold" noMargin>
        {`© ${copyrightYear} Nationale-Nederlanden. Wszelkie prawa zastrzeżone.`}
      </Typo>
    </FooterRow>
  </FooterPaddings>
);
