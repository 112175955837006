import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors } from '../../colors';
import { ElementFormWrapperHorizontal } from '../Form/ElementFormWrapperHorizontal';
import IconOK from './icon-ok.svg';

interface CheckboxViewProps {
  disabled?: boolean;
  error?: boolean;
}

const CheckboxView = styled.input<CheckboxViewProps>`
  appearance: none;
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  border: 1px solid;
  background: white;
  margin: 0;
  border-radius: 4px;
  padding: 0;
  flex-shrink: 0;
  cursor: pointer;
  ${({ disabled }): CSSProp =>
    disabled
      ? css`
          border-color: ${nnColors.greyWhite};
          background: ${nnColors.greyWhite};
          cursor: not-allowed;
        `
      : css`
          border-color: ${nnColors.greyLight};
          background: white;
          cursor: pointer;
          &:hover {
            border-color: ${nnColors.orangeMedium};
            box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
          }
        `}
  ${({ error }): CSSProp =>
    error
      ? css`
          border-color: ${nnColors.red};
        `
      : ''}
`;

const CheckboxTick = styled(IconOK)<{
  value: boolean;
  disabled?: boolean;
  error?: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 150ms;
  ${({ error }): CSSProp =>
    error
      ? css`
          path {
            stroke: ${nnColors.red};
          }
        `
      : ''}
  ${({ disabled }): CSSProp =>
    disabled
      ? css`
          path {
            stroke: ${nnColors.greyLight};
          }
        `
      : ''}
  ${({ value }): CSSProp =>
    value
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

export interface CheckboxProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'disabled' | 'name' | 'onChange' | 'value'
  > {
  id?: string;
  value: boolean;
  onChange: (_: boolean) => void;
  disabled?: boolean;
  label?: React.ReactNode;
  error?: string;
  noMargin?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  label,
  disabled,
  error,
  onChange,
  noMargin,
  ...props
}) => (
  <ElementFormWrapperHorizontal
    disabled={disabled}
    error={error}
    label={label}
    noMargin={noMargin}
  >
    <CheckboxView
      disabled={disabled}
      error={!!error}
      onChange={(): void => (disabled ? undefined : onChange(!value))}
      type="checkbox"
      value={`${value}`}
      {...props}
    />
    <CheckboxTick disabled={disabled} error={!!error} value={value} />
  </ElementFormWrapperHorizontal>
);
