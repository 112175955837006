/* eslint-disable new-cap */
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors, primaryColors } from '../../colors';
import Close from '../Pictogram/icons/close.svg';
import Ok from '../Pictogram/icons/ok.svg';
import type { TypoProps } from '../Typo/Typo';
import { getTypoStyle } from '../Typo/Typo';

interface BulletProps {
  bColor?: keyof typeof nnColors;
  dense?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

interface StyledChipProps {
  bColor?: keyof typeof nnColors;
  dense?: boolean;
  bullet?: boolean;
  smallCaps?: boolean;
  $color: keyof typeof primaryColors;
  $type: TypoProps['$type'];
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  variant: 'button' | 'filter' | 'label';
  isCircle?: boolean;
}

const IconBaseCSS = css`
  transition: 150ms opacity ease-in, 150ms width ease-in, 150ms margin ease-in;
  position: absolute;
  right: 2px;
  width: 0;
  margin-left: 0;
  opacity: 1;
  transform: scale(0.7);
`;

export const IconClose = styled(Close)`
  ${IconBaseCSS}
  path {
    stroke: ${({ color }: { color: keyof typeof nnColors }): CSSProp =>
      nnColors[color]};
  }
`;

export const IconOk = styled(Ok)`
  ${IconBaseCSS}
  path {
    stroke: ${({ color }: { color: keyof typeof nnColors }): CSSProp =>
      nnColors[color]};
  }
`;

const ButtonChip = (
  bColor?: keyof typeof nnColors,
  dense?: boolean,
  selected?: boolean,
  disabled?: boolean,
): CSSProp => css`
  border: 1px solid;
  border-color: ${nnColors.greyWhite};
  background: transparent;
  color: ${nnColors.greyMedium};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 150ms ease-in, box-shadow 150ms ease-in,
    padding 150ms ease-in, background-color 150ms ease-in, color 150ms ease-in,
    padding 150ms ease-in;

  ${selected &&
  css`
    background-color: ${bColor ? nnColors[bColor] : primaryColors.orangeMedium};
    color: white;
    border-color: ${bColor ? nnColors[bColor] : primaryColors.orangeMedium};
    &:hover,
    &:active {
      svg:nth-of-type(1) {
        width: 24px;
        margin-left: 8px;
        opacity: 1;
      }
      svg:nth-of-type(2) {
        width: 0;
        margin-left: 0;
        opacity: 1;
      }
    }
    &:not(:hover) {
      svg:nth-of-type(2) {
        width: 24px;
        margin-left: 8px;
        opacity: 1;
      }
    }
  `}

  ${dense
    ? css`
        padding: ${selected ? '6px 26px 6px 10px' : '6px 10px 6px 26px'};
        height: 24px;
        border-radius: 20px;
      `
    : css`
        padding: ${selected ? '0px 26px 0px 10px' : '0px 10px 0px 26px'};
        height: 32px;
        border-radius: 32px;
      `}

    ${disabled
    ? css`
        background-color: ${nnColors.greyWhite};
        pointer-events: none;
        cursor: not-allowed;
        color: ${nnColors.greyLight};
      `
    : css`
        &:hover,
        &:active {
          border-color: ${bColor
            ? nnColors[bColor]
            : primaryColors.orangeMedium};
          box-shadow: 0px 5px 15px
            ${bColor
              ? `${nnColors[bColor]}1a`
              : `${primaryColors.orangeMedium}1a`};
        }
      `}
`;

const LabelChip = (
  bColor?: keyof typeof nnColors,
  dense?: boolean,
  isCircle?: boolean,
): CSSProp => css`
  background: ${(): CSSProp =>
    bColor ? nnColors[bColor] : primaryColors.orangeMedium};
  width: fit-content;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  border: none;
  font-family: NNDagnyDisplay;

  ${dense
    ? css`
        line-height: 14px;
        padding: 5px 10px;
        height: 24px;
        border-radius: 20px;
        padding: ${isCircle ? '5px 5px' : '5px 10px'};
        width: ${isCircle ? '24px' : 'auto'};
        ${isCircle &&
        css`
          min-width: 0;
        `}
      `
    : css`
        line-height: 16px;
        height: 32px;
        border-radius: 32px;
        padding: ${isCircle ? '8px 8px' : '8px 16px'};
        width: ${isCircle ? '32px' : 'auto'};
        ${isCircle &&
        css`
          min-width: 0;
        `}
      `}
`;

const FilterChip = (
  bColor?: keyof typeof nnColors,
  dense?: boolean,
): CSSProp => css`
  border: 1px solid;
  border-color: ${nnColors.greyWhite};
  background: transparent;
  color: ${nnColors.greyMedium};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg:nth-of-type(1) {
    width: 24px;
    margin-left: 8px;
    opacity: 1;
  }
  background-color: ${bColor ? nnColors[bColor] : primaryColors.orangeMedium};
  border-color: ${bColor ? nnColors[bColor] : primaryColors.orangeMedium};
  ${dense
    ? css`
        padding: 6px 26px 6px 10px;
        height: 24px;
        border-radius: 20px;
      `
    : css`
        padding: 0px 26px 0px 10px;
        height: 32px;
        border-radius: 32px;
      `}
`;

export const StyledChip = styled.button<StyledChipProps>`
  position: relative;
  color: ${({ $color }): CSSProp => nnColors[$color]};
  min-width: 27px;
  box-sizing: border-box;
  ${getTypoStyle}
  ${({ smallCaps }): CSSProp | false | undefined =>
    smallCaps &&
    css`
      font-variant: small-caps;
    `}

  ${({ bColor, dense, selected, disabled, isCircle, variant }): CSSProp => css`
    ${variant === 'button' && ButtonChip(bColor, dense, selected, disabled)}
    ${variant === 'label' && LabelChip(bColor, dense, isCircle)}
    ${variant === 'filter' && FilterChip(bColor, dense)}
  `}
`;

export const Bullet = styled.div<BulletProps>`
  transition: width 150ms ease-in, margin 150ms ease-in, padding 150ms ease-in;
  box-sizing: border-box;
  position: absolute;
  left: 9px;
  ${({ selected }): CSSProp =>
    selected
      ? css`
          width: 0;
          height: 0;
          margin-right: 0;
        `
      : css`
          width: 8px;
          height: 8px;
          margin-right: 8px;
        `}
  border-radius: 100%;

  ${({ disabled, bColor }): CSSProp =>
    disabled
      ? css`
          background: ${nnColors.greyLight};
          cursor: not-allowed;
        `
      : css`
          background: ${(): CSSProp =>
            bColor ? nnColors[bColor] : primaryColors.orangeMedium};
        `}
`;
