import type { ReactNode } from 'react';
import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { nnColors, primaryColors, secondarySuplement } from '../../colors';
import { Typo } from '../Typo/Typo';
import { CaptionField } from './CaptionField';
import { ErrorField } from './ErrorField';

export interface FormElementProps {
  label?: string;
  required?: boolean;
  error?: string;
  caption?: string;
  disabled?: boolean;
  showInvalid?: boolean;
  filledUp?: boolean;
  cursor?: string;
  icon?: ReactNode;
  className?: string;
  dense?: boolean;
  noMargin?: boolean;
  clickableIcon?: boolean;
}

interface InputValidationProps {
  disabled?: boolean;
  $required?: boolean;
  cursor?: string;
  dense?: boolean;
}

const InputWrapper = styled.span<{ noMargin?: boolean }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ noMargin }): CSSProp => (!noMargin && '16px') || ''};
`;

interface StyledLabelProps extends InputValidationProps {
  filledUp: boolean;
  showInvalid?: boolean;
}

export const StyledLabel = styled.div<StyledLabelProps>`
  position: absolute;
  padding: 10px 44px 0 16px;
  font-family: NNDagnyText;
  transition: font-size 150ms ease-in, padding 150ms ease-in;
  cursor: ${({ cursor }): CSSProp => cursor || 'text'};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: calc(100% - 60px);

  ${Typo} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ dense }): CSSProp =>
    (dense &&
      css`
        display: none;
      `) ||
    ''}

  ${({ $required, filledUp }): CSSProp =>
    ($required &&
      css`
        &:after {
          content: '';
          position: absolute;
          margin-left: ${filledUp ? '4px' : '1px'};
          background: ${primaryColors.orangeMedium};
          height: ${filledUp ? '5px' : '4px'};
          width: ${filledUp ? '5px' : '4px'};
          right: ${filledUp ? '36px' : '38px'};
          border-radius: 10px;
          transition: height 150ms ease-in, width 150ms ease-in,
            right 150ms ease-in;
        }
      `) ||
    ''}

  ${({ disabled }): CSSProp =>
    (disabled &&
      css`
        cursor: not-allowed;
      `) ||
    ''}

  ${({ showInvalid }): CSSProp =>
    (showInvalid &&
      css`
        ${Typo} {
          color: ${secondarySuplement.red};
        }
      `) ||
    ''}

  ${({ filledUp }): CSSProp =>
    (filledUp &&
      css`
        padding: 21px 44px 0 16px;
        ${Typo} {
          font-size: 16px;
        }
      `) ||
    css`
      padding: 10px 44px 0 16px;
      ${Typo} {
        font-size: 12px;
      }
    `}
`;

const Label = styled.label`
  width: 100%;
`;

interface IconWrapperProps {
  disabled?: boolean;
  dense?: boolean;
  clickableIcon?: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  top: 18px;
  right: 16px;
  cursor: ${({ clickableIcon }): CSSProp =>
    clickableIcon ? 'pointer' : 'auto'};
  ${({ dense }): CSSProp =>
    dense
      ? css`
          top: 13px;
        `
      : css`
          top: 18px;
        `}
  ${({ disabled }): CSSProp =>
    (disabled &&
      css`
        cursor: not-allowed;
        svg path {
          stroke: ${nnColors.greyLight};
        }
      `) ||
    ''}
`;

export const ElementFormWrapper: React.FC<FormElementProps> = ({
  label,
  required,
  error,
  caption,
  filledUp,
  disabled,
  showInvalid,
  children,
  cursor,
  icon,
  className,
  dense,
  noMargin,
  clickableIcon,
}) => (
  <InputWrapper className={className} noMargin={noMargin}>
    <Label>
      <StyledLabel
        $required={required}
        cursor={cursor}
        dense={dense}
        disabled={disabled}
        filledUp={!!filledUp}
        showInvalid={showInvalid}
      >
        <Typo $color="greyMedium" $type="body" noMargin>
          {label}
        </Typo>
      </StyledLabel>
      {children}
      <IconWrapper
        clickableIcon={clickableIcon}
        dense={dense}
        disabled={disabled}
      >
        {icon}
      </IconWrapper>
    </Label>
    {error ? <ErrorField error={error} /> : <CaptionField caption={caption} />}
  </InputWrapper>
);
