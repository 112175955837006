import type { FC } from 'react';
import type { CSSProp } from 'styled-components';
import styled from 'styled-components';

import { nnColors } from '../../colors';
import { Grid } from '../Grid/Grid';
import type { Props as PictogramProps } from '../Pictogram/Pictogram';
import { Pictogram } from '../Pictogram/Pictogram';

interface RoundedPictogramWrapperProps {
  background: keyof typeof nnColors;
  iconSize?: 'l' | 'm' | 's' | 'sl' | 'xl' | 'xs' | 'xxl' | 'xxxl';
}

const paddings = {
  xs: '2px',
  s: '2.67px',
  sl: '4px',
  m: '4px',
  l: '8px',
  xl: '12px',
  xxl: '20px',
  xxxl: '32px',
};

const RoundedPictogramWrapper = styled(Grid)<RoundedPictogramWrapperProps>`
  border-radius: 100%;
  background-color: ${({ background }): CSSProp => nnColors[background]};
  transition: background-color ease 0.2s;
  padding: ${({ iconSize }): CSSProp =>
    paddings[iconSize as keyof typeof paddings]};
  width: fit-content;
`;

export interface Props extends PictogramProps {
  background: keyof typeof nnColors;
}

export const RoundedPictogram: FC<Props> = ({
  background,
  iconColor,
  iconSize,
  ...props
}) => (
  <RoundedPictogramWrapper
    align
    background={background}
    center
    flex={0}
    iconSize={iconSize}
  >
    <Pictogram iconColor={iconColor} iconSize={iconSize} {...props} />
  </RoundedPictogramWrapper>
);
