import type { CSSProp } from 'styled-components';
import styled, { css } from 'styled-components';

import { MarginsWrapper } from '../Margins/Margins';
import { Typo } from '../Typo/Typo';
import { ErrorField } from './ErrorField';

export interface ElementFormWrapperHorizontalProps {
  label?: React.ReactNode;
  error?: string;
  disabled?: boolean;
  noMargin?: boolean;
}

const InputWrapper = styled.div`
  position: relative;
  height: 24px;
`;

const Label = styled.label<{ disabled?: boolean; noMargin?: boolean }>`
  display: flex;
  ${({ noMargin }): CSSProp =>
    noMargin
      ? ''
      : css`
          margin-bottom: 16px;
        `}
  ${({ disabled }): CSSProp =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

const LabelText = styled(Typo)`
  margin-top: 3px;
`;

export const ElementFormWrapperHorizontal: React.FC<
  ElementFormWrapperHorizontalProps
> = ({ label, error, disabled, noMargin, children }) => (
  <span>
    <Label disabled={disabled} noMargin={noMargin}>
      <MarginsWrapper marginRight={label ? 'M' : undefined}>
        <InputWrapper>{children}</InputWrapper>
      </MarginsWrapper>
      {label && (
        <LabelText $type="body" noMargin>
          {label}
        </LabelText>
      )}
    </Label>
    <ErrorField error={error} />
  </span>
);
